import { Component, inject, Input, OnChanges, SimpleChanges, TemplateRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductImageService } from '@cia-front-end-apps/shared/product-image-provider/product-image.service';

@Component({
    selector: 'gc-product-image-provider',
    templateUrl: './product-image-provider.component.html',
    styleUrls: ['./product-image-provider.component.scss'],
})
export class ProductImageProviderComponent implements OnChanges {
    private productImageService = inject(ProductImageService);

    @Input() product: { isAssortmentHostedImg: boolean, image: string };
    @Input() contentTemplate: TemplateRef<any>;

    imageSource$: Observable<string>;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product) {
            this.imageSource$ = this.getImageSource();
        }
    }

    private getImageSource(): Observable<string> {
        if (!this.product) {
            return of(null);
        }

        if (this.product.isAssortmentHostedImg) {
            return this.productImageService.getAssortmentImage(this.product).result$.pipe(
                map(result => {
                    if (result.isSuccess) {
                        const base64String = result.data;
                        return `data:image/png;base64,${base64String}`;
                    }
                    return null;
                })
            );
        } else {
            return of(this.product.image);
        }
    }
}

<button mat-icon-button
        color="accent"
        class="filter-menu-button"
        type="button"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="filterMenu">
    <mat-icon>filter_list</mat-icon>
</button>

<mat-menu #filterMenu="matMenu"
          class="filter-menu"
          yPosition="below"
          xPosition="before">

    <div gcPreventMenuClose
         class="toolbar-header">
        <span *ngIf="!showApplyButton"
              class="title">
            Filter
        </span>
        <button *ngIf="showApplyButton"
                mat-flat-button
                class="button"
                color="accent"
                (click)="onClose()">
            Apply
        </button>
        <button mat-button
                class="button"
                color="accent"
                (click)="onClearFilters()">
            Clear
        </button>
    </div>

    <mat-accordion gcPreventMenuClose
                   displayMode="flat"
                   [multi]="true">
        <mat-expansion-panel *ngIf="sortOptions && sortOptions.length > 0">
            <mat-expansion-panel-header class="section-heading"
                                        expandedHeight="48px">
                <mat-panel-title class="section-title">Sort Options</mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <hq-sort-menu [sortOptions]="sortOptions"
                              [clearSort]="clearSort"
                              [defaultSortType]="defaultSortType"
                              (changeSortOption)="onChangeSortOption($event)">
                </hq-sort-menu>
            </ng-template>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="!!openFilterGroup"
                             *ngIf="filterGroups && filterGroups.length > 0">
            <mat-expansion-panel-header class="section-heading"
                                        expandedHeight="48px">
                <mat-panel-title class="section-title">Filters</mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <mat-accordion displayMode="flat"
                               [multi]="true">
                    <ng-container *ngFor="let group of filterGroups">
                        <mat-expansion-panel [expanded]="group.id === openFilterGroup">
                            <mat-expansion-panel-header class="section-subheading"
                                                        expandedHeight="48px">
                                <mat-panel-title>{{ group.displayName }}</mat-panel-title>
                            </mat-expansion-panel-header>

                            <ng-template matExpansionPanelContent>
                                <mat-selection-list color="accent"
                                                    class="filter-list"
                                                    (selectionChange)="onChangeFilter($event, group.id)">
                                    <cdk-virtual-scroll-viewport itemSize="44"
                                                                 minBufferPx="384"
                                                                 maxBufferPx="480"
                                                                 [style]="{
                                                                     'height': getFilterGroupScrollHeight(filters[group.id])+'px',
                                                                 }">
                                        <mat-list-option
                                            *cdkVirtualFor="let filter of filters[group.id]; trackBy: filterTrackBy"
                                            [value]="filter.key"
                                            [selected]="filter.isSelected">
                                            <span matListItemTitle
                                                  class="filter-description">
                                                {{ filter.description }}
                                            </span>
                                        </mat-list-option>
                                    </cdk-virtual-scroll-viewport>
                                </mat-selection-list>
                            </ng-template>
                        </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</mat-menu>
